const DEV  = 'development'
const TEST = 'test'
const PROD = 'production'

const getEnvironment = env => {
    if ( env === DEV )
        return {
            environment      : DEV,      
            nodeServer       : 'https://192.168.100.41:9000/js',
            nodeSocketServer : 'ws://192.168.100.41:9001',
            version          : '3.6'
        }
    if ( env === TEST )
        return {
            environment      : TEST,
            nodeServer       : 'https://rmutest.standardnutrition.com/js',
            nodeSocketServer : 'wss://rmutest.standardnutrition.com:9001',
            version          : '3.6'
        }
    if ( env === PROD )
        return {
            environment      : PROD,
            nodeServer       : 'https://rmu.standardnutrition.com/js',
            nodeSocketServer : 'wss://rmu.standardnutrition.com:9001',
            version          : '3.2'
        }
}

module.exports = {
    getEnv : () => {
        // return getEnvironment( DEV )
        return getEnvironment( TEST )
        // return getEnvironment( PROD )
    }
}
